import { IconProps } from "./types";

function ImportIcon({ width = "24", height = "24", ...props }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 13L11.2929 16.2929C11.6834 16.6834 12.3166 16.6834 12.7071 16.2929L16 13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M12 16V3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M7.21875 8H6.5C4.84315 8 3.5 9.34315 3.5 11V18C3.5 19.6569 4.84315 21 6.5 21H17.5C19.1569 21 20.5 19.6569 20.5 18V11C20.5 9.34315 19.1569 8 17.5 8H16.7812"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default ImportIcon;
