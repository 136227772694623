import useLatest from "@react-hook/latest";
import { CaptionEngine, CaptionStyle, CaptionStylePreset } from "captions-engine";
import { t } from "i18next";
import { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "~/components/Button";
import Delete from "~/components/icons/Delete";
import EditBare from "~/components/icons/EditBare";
import { Tooltip } from "~/components/Tooltip";
import { useStandardDialogs } from "~/context/StandardDialogsContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useClickWithoutFocus } from "~/hooks/useClickWithoutFocus";
import { colorToHex } from "~/utils/colorConversion";

import { DEFAULT_ACTIVE_WORD_BACKGROUND } from "../../constants/captionStyle";
import { CaptionPage, generateCaptionPages } from "../../utils/captionProcessing";
import { createCaptionEngine } from "../../utils/createCaptionEngine";

import {
  STYLE_PANEL_OVERRIDES,
  STYLE_PANEL_PREVIEW_DELAY_SECONDS,
  STYLE_PANEL_TEST_WORDS,
  TEST_PAGE_PREVIEW_FPS,
} from "./CaptionTemplatePanelItem.data";
import {
  CaptionsTemplatePanelItemDescription,
  CaptionsTemplatePanelItemPreview,
  CaptionsTemplatePanelItemPreviewWrapper,
  CaptionTemplatePanelColorSwatch,
  CaptionTemplatePanelIcons,
  CaptionTemplatePanelItemColors,
  CaptionTemplatePanelItemContainer,
  CaptionTemplatePanelItemLeft,
  CaptionTemplatePanelItemName,
  CaptionTemplatePanelToggleItem,
  CaptionTemplatePanelItemPreviewSkeleton,
} from "./CaptionTemplatePanelItem.styles";
import { CaptionTemplatePanelItemProps } from "./CaptionTemplatePanelItem.types";

function getPreviewDefaultStartTime(pages: CaptionPage[]): number {
  const firstPage = pages[0];
  const lastLineFromFirstPage = firstPage?.lines[firstPage.lines.length - 1];
  const lastWordFromFirstPage =
    lastLineFromFirstPage?.words[lastLineFromFirstPage.words.length - 1];
  if (!lastWordFromFirstPage) {
    return 0;
  }
  return Math.max(
    lastWordFromFirstPage.endTime - 0.01,
    (lastWordFromFirstPage.startTime + lastWordFromFirstPage.endTime) / 2
  );
}

function getPreviewDesiredFontSize(pages: CaptionPage[]): number {
  if (pages.some((page) => page.lines.some((line) => line.words.length >= 6))) {
    return 14;
  } else if (pages.every((page) => page.lines.length == 1 && page.lines[0].words.length == 1)) {
    return 32;
  }
  return 22;
}

/**
 * Creates a caption styles selector panel item component.
 *
 * @param presetEntry - the caption preset represented by this item
 * @param countryCode - language-country code for the subtitles
 * @param words - the words used to preview the preset
 * @returns the JSX element
 * @constructor
 */
export function CaptionTemplatePanelItem({
  templateEntry,
  countryCode,
  words,
  selected,
  onEditTemplate,
  onDeleteTemplate,
  colorScheme,
}: Readonly<CaptionTemplatePanelItemProps>): ReactElement {
  const analytics = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(-1);
  const testCanvas = useRef<HTMLCanvasElement>(null);
  const hoverInterval = useRef<NodeJS.Timeout | null>(null);
  const canvasCssHeight = useRef<number>(0);
  const downscalingRatio = useRef<number>(0);
  const renderEngine = useRef<CaptionEngine | null>(null);
  const animationRef = useRef<number | null>(null);
  const [previewHeight, setPreviewHeight] = useState<number>(0);
  const { handleClickWithoutTriggeringFocus } = useClickWithoutFocus();
  const { confirmDialog } = useStandardDialogs();
  const testPreset: CaptionStylePreset = useMemo(
    () => ({
      ...templateEntry.style.content,
    }),
    [templateEntry]
  );
  const testStyle: CaptionStyle = useMemo(
    () => ({
      ...STYLE_PANEL_OVERRIDES,

      // Retain the color scheme defined in the template for the preview,
      // rather than using the user's current customization.
      activeColor: templateEntry.colors.active,
      textColor: templateEntry.colors.primary,
      emphasisColor: templateEntry.colors.emphasis,
      wordBackground: templateEntry.colors.wordBackground,
      emojiSettings: templateEntry.emojiSettings,
      emphasisSettings: templateEntry.emphasisSettings,
    }),
    [templateEntry]
  );
  const testWords = useMemo(() => {
    if (words && words.length > 0) {
      return words.map((word) => ({
        ...word,
        supersize: false,
      }));
    } else {
      return STYLE_PANEL_TEST_WORDS;
    }
  }, [words]);
  const testPages = useMemo(
    () => generateCaptionPages(testWords, templateEntry.style.content),
    [templateEntry, testWords]
  );
  const colorSwatchStyles = useMemo(() => {
    const activeWordBackground =
      templateEntry.style.content.activeWordBackground.color ?? DEFAULT_ACTIVE_WORD_BACKGROUND;

    return {
      text: { backgroundColor: colorToHex(templateEntry.colors.primary) },
      emphasis: { backgroundColor: colorToHex(templateEntry.colors.emphasis) },
      activeWordBackground: { backgroundColor: colorToHex(activeWordBackground) },
      activeWord: { backgroundColor: colorToHex(templateEntry.colors.active) },
      captionsBackground: { backgroundColor: colorToHex(templateEntry.colors.wordBackground) },
    };
  }, [
    templateEntry.colors.primary,
    templateEntry.colors.emphasis,
    templateEntry.style.content.activeWordBackground.color,
    templateEntry.colors.active,
    templateEntry.colors.wordBackground,
  ]);
  const latestTimestamp = useLatest(timestamp);

  const drawCaption = useCallback(async () => {
    animationRef.current = null;
    const canvas = testCanvas.current;
    if (!canvas) {
      return;
    }
    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }
    let engine = renderEngine.current;
    if (!engine) {
      setIsLoading(true);
      engine = createCaptionEngine();
      renderEngine.current = engine;
      canvasCssHeight.current = 0;
    }
    // Negative timestamps are treated as the preview delay, so paint the default frame
    const timestamp =
      latestTimestamp.current >= 0
        ? latestTimestamp.current
        : getPreviewDefaultStartTime(testPages);
    // Factor to scale the font to the desired preview size
    const baseFontSize = testPreset.font.fontSize;
    //if (testPreset.custom)
    const fontFactor = getPreviewDesiredFontSize(testPages) / baseFontSize;
    // Factor to scale down the preview when the canvas is smaller than the default width
    const widthFactor = canvas.offsetWidth / 327;
    const scaledTestStyle = {
      ...testStyle,
      sizeFactor: fontFactor * widthFactor * window.devicePixelRatio,
    };

    if (canvasCssHeight.current === 0) {
      await engine.setCaptions(testPages, countryCode).catch(console.error);
      engine.setStyle(testPreset, scaledTestStyle);
      engine.setArea(canvas.offsetWidth, canvas.offsetHeight);
      await engine.setContext(context).catch(console.error);
      const overallBox = engine.overallBoxes.find((box) => box.boxType === "captions");
      downscalingRatio.current = Math.min(
        1,
        canvas.offsetWidth / ((overallBox?.width ?? 0) / window.devicePixelRatio + 32)
      );
      canvasCssHeight.current = Math.max(
        104,
        (overallBox?.height ?? 0) / downscalingRatio.current / window.devicePixelRatio + 32
      );
      setPreviewHeight(canvasCssHeight.current);
    }

    canvas.height = (canvasCssHeight.current * window.devicePixelRatio) / downscalingRatio.current;
    canvas.width = (canvas.offsetWidth * window.devicePixelRatio) / downscalingRatio.current;
    const margin = (16 * window.devicePixelRatio) / downscalingRatio.current;
    engine.setArea(canvas.width - 2 * margin, canvas.height);
    await engine.setContext(context).catch(console.error);
    context.clearRect(0, 0, canvas.width, canvas.height);
    await engine.setTimestamp(timestamp);
    await engine.draw(margin, 0);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testPreset, testStyle, testPages, countryCode]);

  const handlePointerOver = useCallback(() => {
    if (hoverInterval.current) {
      return;
    }
    setTimestamp(-STYLE_PANEL_PREVIEW_DELAY_SECONDS);
    hoverInterval.current = setInterval(() => {
      setTimestamp((prev) => {
        const result = prev + 1 / TEST_PAGE_PREVIEW_FPS;
        return result > testPages[testPages.length - 1].endTime ? 0 : result;
      });
    }, 1000 / TEST_PAGE_PREVIEW_FPS);
  }, [testPages, analytics, templateEntry.id, templateEntry.displayName]);

  const handlePointerLeave = useCallback(() => {
    if (hoverInterval.current) {
      clearInterval(hoverInterval.current);
      hoverInterval.current = null;
    }
    setTimestamp(-1);
  }, []);

  const handleTemplateEdit = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      onEditTemplate?.(templateEntry.id);
    },
    [onEditTemplate, templateEntry]
  );

  const handleTemplateDelete = useCallback(() => {
    confirmDialog(
      t("projectView:templates-panel.template-list.template-item.delete-dialog.text", {
        template: templateEntry.displayName,
      }),
      {
        type: "delete",
        title: t("projectView:templates-panel.template-list.template-item.delete-dialog.title"),
      }
    ).then((shouldDelete) => {
      if (shouldDelete) {
        onDeleteTemplate?.(templateEntry.id);
      }
    });
  }, [onDeleteTemplate, templateEntry, confirmDialog]);

  const latestDrawCaption = useLatest(drawCaption);

  useEffect(() => {
    canvasCssHeight.current = 0;
    animationRef.current ??= requestAnimationFrame(latestDrawCaption.current);
  }, [testPreset, testStyle, testPages, countryCode]);

  useEffect(() => {
    animationRef.current ??= requestAnimationFrame(latestDrawCaption.current);
  }, [timestamp]);

  useEffect(() => {
    return () => {
      renderEngine.current?.destroy();
      renderEngine.current = null;
    };
  }, []);

  const multipleIcons = !!onDeleteTemplate && !!onEditTemplate;

  return (
    <CaptionTemplatePanelItemContainer>
      <CaptionTemplatePanelToggleItem
        value={templateEntry.id}
        aria-label={templateEntry.displayName}
        onClick={handleClickWithoutTriggeringFocus}
        onPointerOver={handlePointerOver}
        onPointerLeave={handlePointerLeave}
        selected={Boolean(selected)}
        colorScheme={colorScheme}
      >
        <CaptionsTemplatePanelItemPreviewWrapper style={{ height: `${previewHeight}px` }}>
          {isLoading && <CaptionTemplatePanelItemPreviewSkeleton />}
          <CaptionsTemplatePanelItemPreview ref={testCanvas} />
        </CaptionsTemplatePanelItemPreviewWrapper>
        <CaptionsTemplatePanelItemDescription>
          <CaptionTemplatePanelItemLeft>
            <CaptionTemplatePanelItemName>{templateEntry.displayName}</CaptionTemplatePanelItemName>
          </CaptionTemplatePanelItemLeft>

          <CaptionTemplatePanelItemColors>
            <Tooltip
              tooltipText={t("projectView:templates-panel.template-list.template-item.colors.text")}
            >
              <CaptionTemplatePanelColorSwatch css={colorSwatchStyles.text} />
            </Tooltip>

            <Tooltip
              tooltipText={t(
                "projectView:templates-panel.template-list.template-item.colors.emphasis"
              )}
            >
              <CaptionTemplatePanelColorSwatch css={colorSwatchStyles.emphasis} />
            </Tooltip>

            <Tooltip
              tooltipText={t(
                "projectView:templates-panel.template-list.template-item.colors.active-word-background"
              )}
            >
              <CaptionTemplatePanelColorSwatch css={colorSwatchStyles.activeWordBackground} />
            </Tooltip>

            <Tooltip
              tooltipText={t(
                "projectView:templates-panel.template-list.template-item.colors.active-word"
              )}
            >
              <CaptionTemplatePanelColorSwatch css={colorSwatchStyles.activeWord} />
            </Tooltip>

            <Tooltip
              tooltipText={t(
                "projectView:templates-panel.template-list.template-item.colors.captions-background"
              )}
            >
              <CaptionTemplatePanelColorSwatch css={colorSwatchStyles.captionsBackground} />
            </Tooltip>
          </CaptionTemplatePanelItemColors>
        </CaptionsTemplatePanelItemDescription>
      </CaptionTemplatePanelToggleItem>

      <CaptionTemplatePanelIcons multipleIcons={multipleIcons}>
        {onDeleteTemplate && (
          <Tooltip
            tooltipText={t(
              "projectView:templates-panel.template-list.template-item.icons.delete-template"
            )}
          >
            <Button
              aria-label={t(
                "projectView:templates-panel.template-list.template-item.icons.delete-template"
              )}
              size="sm"
              onlyIcon
              variant="tertiary"
              colorScheme={colorScheme}
              onMouseDown={handleTemplateDelete}
            >
              <Delete />
            </Button>
          </Tooltip>
        )}
        {onEditTemplate && (
          <Tooltip
            tooltipText={t(
              "projectView:templates-panel.template-list.template-item.icons.edit-template"
            )}
          >
            <Button
              aria-label={t(
                "projectView:templates-panel.template-list.template-item.icons.edit-template"
              )}
              size="sm"
              onlyIcon
              variant="tertiary"
              colorScheme={colorScheme}
              onMouseDown={handleTemplateEdit}
            >
              <EditBare />
            </Button>
          </Tooltip>
        )}
      </CaptionTemplatePanelIcons>
    </CaptionTemplatePanelItemContainer>
  );
}
