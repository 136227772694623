import { styled } from "ui";

import type { IconProps } from "~/components/icons/types";

export const Sparkles = styled((props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.54691 6.97082C9.72647 6.5861 10.2735 6.5861 10.4531 6.97082L12.4687 11.2897C12.5184 11.3961 12.6039 11.4816 12.7103 11.5313L17.0292 13.5469C17.4139 13.7265 17.4139 14.2735 17.0292 14.4531L12.7103 16.4687C12.6039 16.5184 12.5184 16.6039 12.4687 16.7103L10.4531 21.0292C10.2735 21.4139 9.72647 21.4139 9.54691 21.0292L7.5313 16.7103C7.48163 16.6039 7.39609 16.5184 7.28967 16.4687L2.97082 14.4531C2.5861 14.2735 2.5861 13.7265 2.97082 13.5469L7.28967 11.5313C7.39609 11.4816 7.48163 11.3961 7.5313 11.2897L9.54691 6.97082Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M17.5469 2.47082C17.7265 2.0861 18.2735 2.0861 18.4531 2.47082L19.1959 4.06246C19.2456 4.16888 19.3311 4.25443 19.4375 4.30409L21.0292 5.04691C21.4139 5.22647 21.4139 5.77353 21.0292 5.95309L19.4375 6.69591C19.3311 6.74557 19.2456 6.83112 19.1959 6.93754L18.4531 8.52918C18.2735 8.9139 17.7265 8.9139 17.5469 8.52918L16.8041 6.93754C16.7544 6.83112 16.6689 6.74557 16.5625 6.69591L14.9708 5.95308C14.5861 5.77353 14.5861 5.22647 14.9708 5.04691L16.5625 4.30409C16.6689 4.25443 16.7544 4.16888 16.8041 4.06246L17.5469 2.47082Z"
      fill="currentColor"
    />
  </svg>
));
