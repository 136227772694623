import type { CaptionStyle } from "captions-engine";

import type { CaptionWord } from "../../utils/captionProcessing";

export const TEST_PAGE_PREVIEW_FPS = 30;
export const STYLE_PANEL_PREVIEW_DELAY_SECONDS = 0.5;
export const STYLE_PANEL_MIN_WORDS = 9;
export const STYLE_PANEL_MAX_TIME = 8;
export const STYLE_PANEL_WORD_DELAY_OFFSET_SECONDS = 0.16;

export const STYLE_PANEL_TEST_WORDS: CaptionWord[] = [
  {
    id: 1,
    startTime: 0.16,
    endTime: 0.32,
    text: "The",
    supersize: false,
    emphasize: false,
    lineBreakOverride: null,
  },
  {
    id: 2,
    startTime: 0.32,
    endTime: 0.56,
    text: "quick",
    supersize: false,
    emphasize: true,
    lineBreakOverride: null,
  },
  {
    id: 3,
    startTime: 0.56,
    endTime: 0.84,
    text: "brown",
    supersize: false,
    emphasize: false,
    lineBreakOverride: null,
  },
  {
    id: 4,
    startTime: 0.84,
    endTime: 1.28,
    text: "fox",
    supersize: false,
    emphasize: false,
    lineBreakOverride: null,
  },
  {
    id: 5,
    startTime: 1.28,
    endTime: 1.6,
    text: "jumps",
    supersize: false,
    emphasize: false,
    lineBreakOverride: null,
  },
  {
    id: 6,
    startTime: 1.6,
    endTime: 1.76,
    text: "over",
    supersize: false,
    emphasize: false,
    lineBreakOverride: null,
  },
  {
    id: 7,
    startTime: 1.76,
    endTime: 1.96,
    text: "the",
    supersize: false,
    emphasize: false,
    lineBreakOverride: null,
  },
  {
    id: 8,
    startTime: 1.96,
    endTime: 2.32,
    text: "lazy",
    supersize: false,
    emphasize: true,
    lineBreakOverride: null,
  },
  {
    id: 9,
    startTime: 2.32,
    endTime: 3.32,
    text: "dog,",
    supersize: false,
    emphasize: false,
    lineBreakOverride: null,
  },
];

export const STYLE_PANEL_OVERRIDES = {
  sizeFactor: 1,
  positionFactor: { x: 0.5, y: 0.5 },
  rotation: 0,
} satisfies Partial<CaptionStyle>;
