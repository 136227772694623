import * as ToggleGroup from "@radix-ui/react-toggle-group";

import { styled } from "~/theme";

export const CaptionTemplateListContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  height: "100%",
});

export const CaptionTemplateListToggleRoot = styled(ToggleGroup.Root, {
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  paddingBottom: 16,
});

export const CaptionTemplateListNoResults = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$16",
  padding: "$16",
  textAlign: "center",
  alignItems: "center",
  "> svg": {
    color: "$grey-600",
  },
});
